
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";

import { ContentItem } from "@/types";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";


@Component
export default class Index extends Vue {

  items: ContentItem[] = [];

  loading = true;

  dialogs = {
    upload: false,
  }


  async fetchItems() {
    try {
      const items = await getDocs(query(collection(firestore, 'content-items'), where('archived', '==', true)));

      this.items = items.docs.map(e => ({
        ...e.data(),
        id: e.id
      } as ContentItem))
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }

  }

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Bibliotheek', target: 'libary' }])
    this.fetchItems();
  }
}
